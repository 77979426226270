import { on as onEvent } from "common/utils/ui/event";
import { isTouchDevice } from "common/utils/browser";
import { isFunction } from "common/utils/helpers/is";

export default class DeliverToConfirmationWindowClass {
  constructor(
    props = {
      chosenModalWindowSelector: "",
      mainModalWindowSelector: "",
      chosenZipcodeSelector: "",
      chosenCitySelector: "",
      chosenButtonSelector: "",
      clearConfirmationSelector: ""
    }
  ) {
    this.props = props;
    this.clearConfirmationCallback = null;
    this.fetchDomElements();

    onEvent("click", props.clearConfirmationSelector, this.clearConfirmation.bind(this), true);
  }

  fetchDomElements() {
    this.$mainModalWindow = document.querySelector(this.props.mainModalWindowSelector);
    this.$chosenModalWindow = document.querySelector(this.props.chosenModalWindowSelector);
    this.$chosenZipcode = this.$chosenModalWindow?.querySelector(this.props.chosenZipcodeSelector);
    this.$chosenCity = this.$chosenModalWindow?.querySelector(this.props.chosenCitySelector);
    this.$chosenButton = this.$chosenModalWindow?.querySelector(this.props.chosenButtonSelector);

    return this;
  }

  fillWindow({ zipcode, city }) {
    if (zipcode) this.$chosenZipcode.innerText = zipcode;
    if (city) this.$chosenCity.innerText = city.toLowerCase();

    return this;
  }

  showWindow() {
    this.$mainModalWindow.classList.add("hidden");
    this.$chosenModalWindow.classList.remove("hidden");

    // accessibility
    if (!isTouchDevice()) {
      setTimeout(() => {
        this.$chosenButton?.focus();
      }, 0);
    }

    return this;
  }

  clearConfirmation() {
    this.$mainModalWindow.classList.remove("hidden");
    this.$chosenModalWindow.classList.add("hidden");

    isFunction(this.clearConfirmationCallback) && this.clearConfirmationCallback();

    return this;
  }

  onClearConfirmationCallback(callback) {
    this.clearConfirmationCallback = callback;

    return this;
  }
}
