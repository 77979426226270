import { isMobile } from "common/utils/browser";
import DeliverToModalSiteClass from "./classes/deliver-to-modal-site-class";
import DeliverToToggleDesktopClass from "./classes/deliver-to-toggle-desktop-class";
import DeliverToToggleMobileClass from "./classes/deliver-to-toggle-mobile-class";
import DeliverToZipCodeFormClass from "./classes/deliver-to-zip-code-form-class";
import DeliverToUserFormClass from "./classes/deliver-to-user-form-class";
import DeliverToConfirmationWindowClass from "./classes/deliver-to-confirmation-window-class";

class DeliverToSite {
  constructor() {
    this.initModal();
    this.initConfirmWindow();
    this.initZipCodeForm();
    this.initUserForm();
    this.initDeliverToToggle();

    this.initEvents();
  }

  initModal() {
    this.deliverToModal = new DeliverToModalSiteClass({
      modalSelector: "#js-deliver-to-modal-site",
      addressSelector: ".js-deliver-to-mob-addr, .js-deliver-to-address",
      modalInputSelector: ".js-deliver-to-modal-input"
    });
  }

  initConfirmWindow() {
    this.confirmWindow = new DeliverToConfirmationWindowClass({
      chosenModalWindowSelector: ".js-deliver-to-chosen-wrapper",
      mainModalWindowSelector: ".js-deliver-to-form-wrapper",
      chosenZipcodeSelector: ".js-deliver-to-my-zipcode",
      chosenCitySelector: ".js-deliver-to-my-city",
      confirmButtonSelector: ".js-deliver-to-chosen-button"
    });
  }

  initZipCodeForm() {
    this.zipCodeForm = new DeliverToZipCodeFormClass({
      formSelector: ".js-deliver-to-submit-form",
      inputSelector: ".js-deliver-to-modal-input",
      loadingSelector: ".js-deliver-to-modal-loading",
      submitSelector: ".js-deliver-to-modal-submit",
      errorSelector: ".js-deliver-to-modal-form-error"
    });
  }

  initUserForm() {
    this.userForm = new DeliverToUserFormClass({
      formSelector: ".js-deliver-to-user-form",
      inputsSelector: ".js-deliver-to-user-input",
      addressesLinkSelector: ".js-deliver-to-addresses"
    });
  }

  initDeliverToToggle() {
    this.deliverToToggle = isMobile()
      ? new DeliverToToggleMobileClass(".js-deliver-to-toggle-modal", {
          chosenPdpAddressSelector: ".js-deliver-to-pdp-addr",
          chosenAddressSelector: ".js-deliver-to-mob-addr"
        })
      : new DeliverToToggleDesktopClass(".js-deliver-to-toggle-modal", {
          chosenPdpAddressSelector: ".js-deliver-to-pdp-addr",
          chosenAddressSelector: ".js-deliver-to-address",
          titleSelector: ".js-deliver-to-title",
          usernameSelector: ".js-deliver-to-username"
        });
  }

  initEvents() {
    this.deliverToToggle.onToggleCallback(context => {
      this.zipCodeForm.updateTrackAttributeContext(context);
      this.deliverToModal.updateTrackAttributeContext(context).openModal();
    });

    this.deliverToModal.fetchModalBody().then(() => {
      this.zipCodeForm.fetchDomElements();
      this.confirmWindow.fetchDomElements();
      this.userForm.fetchDomElements();
    });

    this.userForm.onSelectAddressCallback(({ zipcode, city, addressID, address, username }) => {
      this.userForm.setAddress(addressID).then(() => {
        this.deliverToToggle.fillAddress({ address, zipcode, username });
        this.confirmWindow.fillWindow({ zipcode, city }).showWindow();
        this.deliverToModal.reloadPageWithModal();
      });
    });

    this.zipCodeForm.onSubmitCallback(({ zipcode, city, address }) => {
      this.userForm.unsetAddress().then(() => {
        this.deliverToToggle.fillAddress({ address, zipcode });
        this.confirmWindow.fillWindow({ zipcode, city }).showWindow();
        this.deliverToModal.reloadPageWithModal();
      });
    });

    this.deliverToModal.onCloseModalCallback(() => {
      this.zipCodeForm.clearForm();
    });
  }
}

// eslint-disable-next-line max-lines-per-function
export default () => new DeliverToSite();
